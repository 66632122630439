import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
  },
  {
    path: '/qui-sommes-nous',
    name: 'QuiSommesNous',
    component: () => import(/* webpackChunkName: "quisommesnous" */ '../views/QuiSommesNous.vue')
  },
  {
    path: '/espace',
    name: 'Espace',
    component: () => import(/* webpackChunkName: "espace" */ '../views/Espace.vue')
  },
  {
    path: '/open-innovation',
    name: 'OpenInnovation',
    component: () => import(/* webpackChunkName: "openinnovation" */ '../views/OpenInnovation.vue')
  },
  {
    path: '/mentions-legales',
    name: 'Mentionslegales',
    component: () => import(/* webpackChunkName: "mentionslegales" */ '../views/Mentionslegales.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
