<template>
  <div class="card-espace-link">
    <h4 class="text-danger font-weight-bolder pt-5" v-html="titleContent"></h4>
    <p v-html="textContent"></p>
    <img :src="require(`@/assets/img/${imgSrc}`)" alt="" />
  </div>
</template>

<script>

export default {
  props: {
    classComponent: String,
    titleContent: String,
    textContent: String,
    imgSrc: String
  }
}
</script>
<style lang="scss">
  .card-espace{
    cursor: pointer;
    z-index: 1;
    position: relative;
    margin-bottom: -2%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px 60px 5px 5px;
    opacity: 1;
    width: 100%;
    &:hover{
      transform: scale(1.02);
      box-shadow: 0px 3px 6px #00000089;
    }
    img{
      position: absolute;
      top: 0;
      right: 0;
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }
  }
</style>
