import 'bootstrap'
import Vue from 'vue'
import BackToTop from 'vue-backtotop'
import App from './App.vue'
import router from './router'
import VueMobileDetection from 'vue-mobile-detection'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueSplide from '@splidejs/vue-splide'

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.use(VueMobileDetection)
Vue.use(BackToTop)
Vue.use(VueSplide)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
