/* eslint-disable vue/valid-v-bind */
<template>
  <div id="app" >
    <header>
      <nav class="navbar navbar-expand-lg navbar-light fixed-top bg-transparent">
        <router-link class="d-block d-lg-none navbar-brand m-3" to="/">
          <img :src="require('./assets/img/svg/logo-mobil.svg')" alt="" />
        </router-link>
        <button class="ml-auto navbar-burger d-block d-lg-none" type="button" data-toggle="collapse" data-target="#navbarExpand"
          aria-controls="navbarExpand" aria-expanded="false" aria-label="Toggle navigation" style="z-index:1;"
          v-on:click="activeClass">
          <div class="burger">
            <div class="burger-icon"></div>
          </div>
        </button>
        <div class="collapse navbar-collapse text-center py-4 py-lg-0 is-desktop bg-secondary" id="navbarExpand">
          <div class="container p-0 d-flex flex-column flex-lg-row align-items-lg-center">
            <router-link class="navbar-brand my-2" to="/">
              <img :src="require('./assets/img/svg/logo-menu.svg')" alt="" class="d-block d-lg-none" />
              <img :src="require('./assets/img/svg/logo-desktop.svg')" alt="" class="d-none d-lg-block" />
            </router-link>
            <ul class="navbar-nav ml-lg-3">
              <li v-on:click="activeClass" class="nav-item my-3 my-lg-0 mx-lg-2">
                <router-link to="/qui-sommes-nous">Qui sommes-nous ?</router-link>
              </li>
              <li v-on:click="activeClass" class="nav-item my-3 my-lg-0 mx-lg-2">
                <router-link to="/open-innovation">Open innovation</router-link>
              </li>
              <li v-on:click="activeClass" class="nav-item my-3 my-lg-0 mx-lg-2">
                <router-link to="/espace">Espace start-up</router-link>
              </li>
              <li v-on:click="activeClass" class="nav-item my-3 d-none d-lg-block my-lg-0 mx-lg-2">
                <router-link to="/#form-contact">Contact</router-link>
              </li>
              <router-link v-on:click="activeClass" to="/contact" class="nav-item d-block d-lg-none btn btn-lg bg-danger btn-nav">Contactez-nous</router-link>
            <a href="https://www.linkedin.com/company/az-initiatys/"
              class=" d-block d-lg-none btn btn-lg bg-info btn-nav" target="_blank">Retrouver-nous sur
              <font-awesome-icon :icon="linkedin" /></a>
            </ul>
            <router-link to="/contact" class="d-block d-lg-none btn btn-lg bg-danger btn-nav">Contactez-nous
            </router-link>
            <a href="https://www.linkedin.com/company/az-initiatys/"
              class=" d-block d-lg-none btn btn-lg bg-info btn-nav" target="_blank">Retrouvez-nous sur
              <font-awesome-icon :icon="linkedin" /></a>
            <a href="https://www.linkedin.com/company/az-initiatys/"
              class="d-none d-lg-flex btn btn-lg bg-info btn-nav mobile m-0 p-0 align-items-center justify-content-center mx-2"
              target="_blank">
              <font-awesome-icon :icon="linkedin" /></a>
          </div>
        </div>
      </nav>
    </header>
    <back-to-top bottom="50px" right="50px">
      <button type="button" visibleoffset="100"
        class="btn-top btn-to-top d-flex align-items-center justify-content-center">
        <font-awesome-icon :icon="top" /></button>
    </back-to-top>

    <div class="large-up">
      <router-view />
    </div>

    <div class="container col-lg-8">
      <p class="py-4 subtitle text-center text-dark text-uppercase"
        v-if="$route.path === '/open-innovation' || $route.path === '/espace' || $route.path === '/qui-sommes-nous'">Voir aussi</p>
      <div class="d-flex justify-content-center flex-column flex-lg-row">
        <div class="card-espace bg-secondary p-4 my-3 mx-lg-2 col-lg-4"
          v-if="$route.path === '/qui-sommes-nous' || $route.path === '/espace'">
          <router-link to="/open-innovation">
            <CardEspace titleContent='Innovation<br>Ouverte'
              textContent="Appui à la création et à la professionnalisation des cellules d'innovation ouverte..."
              imgSrc="cytonn.png" />
          </router-link>
        </div>
        <div class="card-espace bg-secondary p-4 my-3 mx-lg-2 col-lg-4"
          v-if="$route.path === '/qui-sommes-nous'">
          <router-link to="/espace">
            <CardEspace
              titleContent='Start-up'
              textContent="Accompagnement des entrepreneurs dans le développement de leurs projets innovants..."
              imgSrc="img4.png"
            />
          </router-link>
        </div>
        <div class="card-espace bg-secondary p-4 my-3 mx-lg-2 col-lg-4"
          v-if="$route.path === '/open-innovation' || $route.path === '/espace'">
          <router-link to="/qui-sommes-nous">
            <CardEspace titleContent='Hervé<br>Dechene'
              textContent="Fondateur d'AZ Initiatys, spécialiste du développement des start-ups et de l'open innovation..."
              imgSrc="herve1.png" />
          </router-link>
        </div>
        <div class="card-espace bg-secondary p-4 my-3 mx-lg-2 col-lg-4"
          v-if="$route.path === '/open-innovation'">
          <router-link to="/">
            <CardEspace titleContent='Notre<br>mission' textContent="Donner vie aux initiatives entrepreneuriales..."
              imgSrc="austin.png" />
          </router-link>
        </div>
      </div>
    </div>
    <footer>
      <div id="form-contact" class="bg-danger">
        <div class="d-block d-lg-none form-mobile">
          <form class="contact_form" @submit="onSubmit" required>
            <h5 class="py-4 text-center font-italic">Envoyez-nous un message</h5>
            <div class="container">
              <div class="form-group">
                <input class="form-control" type="text" v-model="form.name" required placeholder="Nom" />
              </div>
              <div class="form-group">
                <input class="form-control" type="text" v-model="form.firstname" required placeholder="Prénom" />
              </div>
              <div class="form-group">
                <input class="form-control" type="email" v-model="form.email" required placeholder="E-mail" />
              </div>
              <div class="form-group">
                <input class="form-control" type="text" v-model="form.phone" placeholder="Téléphone" />
              </div>
              <div class="form-group group-textarea">
                <textarea class="form-control" v-model="form.message" rows="3" placeholder="Message"></textarea>
              </div>
              <div v-if="responseEmail.success" :class="'mt-3 alert alert-' + (responseEmail.success === true ? 'success' : 'danger')" role="alert">
              {{responseEmail.message}}
              </div>
              <div class="form-group group-submit">
                <div class="d-flex">
                  <div class="circle-white m-auto">
                    <a href="https://www.linkedin.com/company/az-initiatys/" target="_blank">
                      <font-awesome-icon :icon="linkedin" />
                    </a>
                  </div>
                </div>
                <span class="output_message"></span>
                <button class="ml-auto my-0 btn btn-danger text-center" type="submit" value="Send">Envoyer</button>
              </div>
            </div>
          </form>
        </div>
        <div class="d-none d-lg-block">
          <div class="container form-destock pt-6 position-relative">
            <div class="row p-5">
              <div class="col-lg-8 block-search bg-white">
                <div v-if="responseEmail.success" :class="'mt-3 alert alert-' + (responseEmail.success === true ? 'success' : 'danger')" role="alert">
                  {{responseEmail.message}}
                </div>
                <form class="contact_form p-5" @submit="onSubmit">
                  <h5 class="pb-4 text-dark">Envoyez-nous un message</h5>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input class="form-control" type="text" v-model="form.name" required placeholder="Nom" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input class="form-control" type="text" v-model="form.firstname" required
                          placeholder="Prénom" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input class="form-control" type="text" v-model="form.phone" placeholder="Téléphone" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input class="form-control" type="email" v-model="form.email" required placeholder="Email" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group group-textarea">
                        <textarea class="form-control" v-model="form.message" rows="3" required
                          placeholder="Message"></textarea>
                      </div>
                    </div>
                    <button class="ml-auto mr-3 btn btn-danger text-center" type="submit">Envoyer</button>
                  </div>
                </form>
              </div>
              <div class="col-lg-4 p-5 block-info bg-secondary">
                <h5 class="pb-4 text-dark">Informations</h5>
                <div class="info pt-6">
                  <div class="marker py-4 position-relative">
                    <font-awesome-icon :icon="marker" class="info-icon text-danger " />
                    <a class="info-link d-block"
                      href="https://www.google.fr/maps/place/AZ+INITIATYS/@48.8515513,2.2164417,17z/data=!3m1!4b1!4m5!3m4!1s0x47e67b31b1bda6f3:0x84a52bef725057c0!8m2!3d48.8515513!4d2.2186304"
                      target="_blank">7 rue des Gâte Ceps,<br>92210 SAINT-CLOUD</a>
                  </div>
                  <div class="social row m-auto pt-6">
                    <p class="text-danger font-weight-bold my-auto mr-3">Retrouvez-nous sur</p>
                    <a href="https://www.linkedin.com/company/az-initiatys/"
                      class="btn btn-lg bg-info btn-nav mobile m-0 p-0 d-flex align-items-center justify-content-center mr-2"
                      target="_blank">
                      <font-awesome-icon :icon="linkedin" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="bg-secondary p-2">
          <div class="container">
            <p class="mentions m-1 text-center"><router-link to="/mentions-legales">Mentions légales</router-link></p>
          </div>
        </div>
        </footer>
      </div>
</template>

<script>
import CardEspace from './components/CardEspace'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEnvelopeOpenText, faMapMarkerAlt, faPhoneAlt, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

export default {
  data () {
    return {
      linkedin: faLinkedinIn,
      mail: faEnvelopeOpenText,
      phone: faPhoneAlt,
      marker: faMapMarkerAlt,
      top: faChevronUp,
      form: {
        name: '',
        firstname: '',
        phone: '',
        email: '',
        message: ''
      },
      responseEmail: {}
    }
  },
  components: {
    FontAwesomeIcon,
    CardEspace
  },
  methods: {
    removeClass () {
      console.log('desactiveClass')
    },
    activeClass: function () {
      const button = document.querySelector('.navbar-burger')
      button.classList.toggle('activate')
    },
    createFakeDiv (element, focusElement) {
      const div = document.createElement('div')
      div.setAttribute('class', 'fake-header')
      div.style.width = element.clientWidth + 'px'
      div.style.height = element.clientHeight * (document.body.getBoundingClientRect().width < 980 ? 1 : 1.8) + 'px'
      focusElement.appendChild(div)
    },
    async onSubmit (e) {
      e.preventDefault()
      await axios({
        method: 'post',
        url: `${window.location.origin}/mail.php`,
        data: JSON.stringify(this.form)
      })
      this.responseEmail = {
        success: true,
        message: 'Message envoyé avec succès'
      }
    }
  },
  mounted () {
    if (this) {
      const element = document.querySelector('.navbar')
      const focusElement = document.querySelector('header')
      this.createFakeDiv(element, focusElement)
    }

    const nav = document.getElementById('navbarExpand')
    nav.children[0].children[1].children.forEach(item => {
      item.children[0].addEventListener('click', () => {
        nav.classList.remove('show')
      })
    })
  }
}
</script>

<style lang="scss">
@import './assets/styles/app.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#FFF;
  overflow-x: hidden;
}
nav.navbar{
  padding: 0;
}
.bg-transparent{
  background: transparent;
}
.navbar-brand{
  background: url('/img/svg/logo-menu.svg') center center no-repeat;
}
button.navbar-burger{
  margin: .5rem 1rem;
  background: transparent;
  border: none;
  padding: 0;
  display: inline-block;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity,filter;

  &:focus{
    border-color: none !important;
    border: none;
    outline: none;
  }

  .burger{
    position: relative;
    display: inline-block;
    width: 30px;
    height: 24px;
    .burger-icon,
    .burger-icon::before,
    .burger-icon::after{
      background-color: $danger !important;
    }
    .burger-icon::before, .burger-icon::after{
      content: "";
      display: block;
    }
    .burger-icon, .burger-icon::before, .burger-icon::after{
      position: absolute;
      width: 30px;
      height: 2.5px;
      background-color: $dark;
      transition-timing-function: ease;
      transition-duration: .15s;
      transition-property: transform;
    }
    .burger-icon{
      transition-timing-function: cubic-bezier(.55,.055,.675,.19);
      transition-duration: 75ms;
      top: 50%;
    }
    .burger-icon::before{
      transition: top 75ms ease .12s,opacity 75ms ease;
      top: -8px;
    }
    .burger-icon::after{
      transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19);
      bottom: -8px;
    }
  }

  &.activate{
    .burger{
      .burger-icon{
        transition-delay: .12s;
        transition-timing-function: cubic-bezier(.215,.61,.355,1);
        transform: rotate(45deg);
      }
      .burger-icon::before{
        opacity: 0;
      }
      .burger-icon::after{
        bottom: 0;
        transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
        transform: rotate(-90deg);
      }
    }
  }
}
#navbarExpand{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 35px;
}

@include media-breakpoint-up(lg) {
    .is-desktop{
      position: relative;
      padding: 0;
      background-color: white !important;
      .container{
        justify-content: normal;
      }
    }
  }
.btn-nav{
  width: 90%;
  color: #FFF;
  margin: 5px 0;

  &.mobile{
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }
}
.form-mobile{
  .group-textarea{
    margin-bottom: 0;
  }
  .form-control{
    font-size: 14px;
    margin: 0;
    padding: 0 0 0.75rem;
    color: #FFF;
    background: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #FFF;
      &::placeholder{
        color: #FFF;
      }
  }
  .group-submit{
    display: flex;
    margin-bottom: 0;
    padding: 30px 0;
      .circle-white{
        background: #FFF;
        width: 35px;
        height: 35px;
        margin-right: 12px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
          svg{
            color: $danger;
        }
      }
  }
}
.form-destock{
  .block-search{
    z-index: 1;
    position: relative;
    padding-top: -30px;
    -webkit-box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.1);
    .form-control {
      background: $secondary;
      font-size: 14px;
      border-radius: 5px;
      padding: 28px;
      border: none;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
  }
  .block-info{
    .btn {
      box-shadow: 0px 3px 6px #00000029;
    }
  }
}

.info-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.info-link {
  padding-inline-start: 2rem;
}
.splide__pagination__page{
  &:hover {
    background-color: $danger !important;
  }
  &.is-active {
    background-color: $danger !important;
  }
}

.btn-top{
  width: 40px;
  height: 40px;
  border-radius: 50px;
  -webkit-box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.1);
  color: #fff;
  background-color: $danger;
  border: none;
}

</style>
